





























































































































































































































































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";
import {showTagRE} from "@/utils/common"

@Component({
    name: "mtable",
    components: {}
})

export default class extends Vue {
    // @Prop() private userType !: ''

    showTagRE = showTagRE

    radio1 = '0'

    config = {
        0: '全部',
        2: '黄金会员',
        3: '钻石会员',
        4: '3月内新增会员',
        5: '本月新增会员'
    }

    // 表格数据
    tableData = [];
    tableData2 = [];
    // 加载状态
    loading = false;
    loading2 = false;
    btnloading = false;
    // 总条数
    total = 0;
    total2 = 0;
    // 搜索条件
    search: any = {
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };
    search2: any = {
        userId: '',
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 20, //每页数量
        },
    };

    // 新建标签
    tagDialog = false
    tagVal = ''
    tags: any = []
    newTags: any = []
    delTags: any = []
    rowInfo: any = {}
    querySearch(queryString: any, cb: any) {
        var restaurants = this.labelList;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
    }
    createFilter(queryString: any) {
        return (restaurant: any) => {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    }
    addTag(row: any) {
        this.tagVal = ''
        this.tags = []
        this.newTags = []
        this.delTags = []
        if(row.label) {
            this.tags = this.showTagRE(row.label).split(',')
            // this.tags = row.label.split(',')
        }
        this.rowInfo = row
        this.tagDialog = true
    }
    handleClose(tag: any) {
        this.delTags.push(tag)
        this.tags.splice(this.tags.indexOf(tag), 1);

    }
    handleClose2(tag: any) {
        this.newTags.splice(this.newTags.indexOf(tag), 1);
    }
    aTag() {
        if(!this.tagVal) {
            this.$message.warning('请输入内容')
            return
        }
        let all = [...this.tags, ...this.newTags]
        let a = all.indexOf(this.tagVal)
        if(a>-1) {
            this.$message.warning('存在相同标签')
            return;
        }
        this.newTags.push(this.tagVal)
        this.tagVal = ''
    }
    cancleTag() {
        this.tagDialog = false
    }
    sureTagLoading = false
    sureTag() {
        let obj = {
            userId: this.rowInfo.userId,
            addLabel: this.newTags,
            delLabel: this.delTags
        }
        this.sureTagLoading = true
        api.post('J27604',obj).then(res => {
            this.$message.success('操作成功')
            this.tagDialog = false
            this.searchTable()
            this.labelTable()
        }).finally(() => {
            this.sureTagLoading = false
        })
    }
    labelList = []
    labelTable() {
        api.post('J27605').then(res => {
            this.labelList = res.list
        })
    }

    //日志
    rizhiDialog = false

    getOperationList(row: any) {
        this.loading2 = true
        this.search2.userId = row.userId
        api
            .post('J27172', this.search2)
            .then(res => {
                this.rizhiDialog = true
                this.tableData2 = res.list
                this.total2 = res.total
            }).finally(() => {
            this.loading2 = false
        })
    }

    // 变更等级
    levelDialog = false
    levelInfo = {
        operationContent: '升级为黑卡',
        operationId: "",
        operationName: "",
        operationType: "升级",
        userId: "",
        userType: "-1"
    }
    userType = ""

    changeLevel(row: any) {
        this.userType = row.userType
        this.levelInfo.operationId = this.$store.state.user.user.id
        this.levelInfo.operationName = this.$store.state.user.user.nickname
        this.levelInfo.userId = row.userId
        console.log(this.levelInfo);
        this.levelDialog = true
    }

    updateMemberLevel() {
        api
            .post('J27171', this.levelInfo)
            .then(res => {
                this.$message.success('操作成功')
                this.levelDialog = false
                this.searchTable()
            })
    }

    // 冻结状态
    changeStatus(rows: any) {
        api
            .post('J27152', {userId: this.detailInfo.userId})
            .then(res => {
                this.$message.success('操作成功')
            })
    }

    // 切换
    tab(row: any, e: any) {
        if (e.target.tagName === 'INPUT') return;
        if (row != 4 && row != 5) {
            this.search.userType = row
            this.search.created_at = []
        } else if (row == 4) {
            this.search.userType = ''
            this.search.created_at = this.getmonth(3)
        } else {
            this.search.userType = ''
            this.search.created_at = this.getmonth()
        }
        if (row == 0) {
            this.search.userType = ''
        }
        this.searchTable()
    }

    getmonth(mon?: any) {
        let now: any = new Date(); // 当前日期
        let nowMonth = now.getMonth(); // 当前月
        let nowYear = now.getYear(); // 当前年
        nowYear += (nowYear < 2000) ? 1900 : 0;

        let monthStartDate: any = new Date(nowYear, nowMonth, 1);
        let monthEndDate: any = new Date(nowYear, nowMonth + 1, 1);
        let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
        let startTime = mon ? this.formatDate(new Date(nowYear, nowMonth - 3, 1)) : this.formatDate(new Date(nowYear, nowMonth, 1));
        ;

        let endTime = this.formatDate(new Date(nowYear, nowMonth, days));
        console.log('startTime,endTime', startTime, endTime)
        return [startTime, endTime]
    }

    //日期格式化
    formatDate(date: any) {
        var myyear = date.getFullYear();
        var mymonth = date.getMonth() + 1;
        var myweekday = date.getDate();

        if (mymonth < 10) {
            mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
            myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
    }

    //明细
    mingxi(row: any) {
        this.$router.push({
            name: 'applet_integral_detail',
            query: {
                userId: row.userId
            }
        })
    }

    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post("J27116", this.search)
            .then((data) => {
                this.total = data.total;
                this.tableData = data.list;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    // 详情
    detailDialog = false
    detailInfo: any = {}
    detailInfoC: any = {}
    statusVal: any = true
    userId = ''

    toDetail(rows?: any) {
        api
            .post('J27151', {userId: rows.userId})
            .then(res => {
                this.detailInfo = res
                this.detailInfoC = {...this.detailInfo}
                this.statusVal = this.detailInfo.status == 1 ? true : false
                this.detailDialog = true
                this.userId = rows.userId
            })
    }

    isEdit = false

    editDetail() {
        this.isEdit = true
    }

    cancle() {
        this.isEdit = false
        this.detailInfoC = {...this.detailInfo}
    }

    sureEdit() {
        let obj = {
            userId: this.userId,
            address: {
                recipient: this.detailInfoC.recipient, //收件人姓名
                receivingTelephone: this.detailInfoC.receivingTelephone, //收件人电话号码
                province: this.detailInfoC.province, //省
                city: this.detailInfoC.city, //市
                district: this.detailInfoC.district, //区
                street: this.detailInfoC.street, //街道
                receivingAddress: this.detailInfoC.receivingAddress //详细地址
            },
            realName: {
                fullName: this.detailInfoC.fullName, //真实姓名
                idCardNumber: this.detailInfoC.idCardNumber, //身份证号码
                bankDeposit: this.detailInfoC.bankDeposit, //开户行
                bankAccount: this.detailInfoC.bankAccount //银行卡号
            }
        }
        api
            .post("J27201", obj)
            .then(res => {
                this.$message.success('操作成功')
                this.isEdit = false
                // this.toDetail()
                api
                    .post('J27151', {userId: this.userId})
                    .then(res => {
                        this.detailInfo = res
                        this.detailInfoC = {...this.detailInfo}
                        this.statusVal = this.detailInfo.status == 1 ? true : false
                    })
            })
    }

    // 新建黑卡会员
    addInfo: any = {}
    addDialog = false

    add() {
        this.addInfo = {}
        this.addDialog = true
    }

    sure() {
        api
            .post('A2303', this.addInfo)
            .then(res => {
                this.addDialog = false
                this.searchTable();
            })
    }

    activated() {
        this.searchTable();
        this.labelTable()
    }


}
